<script setup lang="ts">
const { t } = useT();

defineProps<{
	image: string;
	coins: number;
	entries: number;
	depositCondition: number;
}>();
</script>

<template>
	<ACard
		class="affiliate"
		width="292px"
		height="380px"
		:imgAttrs="{
			width: 313,
			height: 386,
			src: image,
			format: 'avif',
			alt: 'background invite affiliate card',
			loading: 'lazy'
		}"
		alt="affiliate"
	>
		<template #top>
			<MPromotionHeadlineFont>
				{{ t("promotionItem.affiliateReferral.title") }}
			</MPromotionHeadlineFont>
			<div class="prizes">
				<AText :size="18" class="title" :modifiers="['medium', 'condensed', 'center']">
					{{ t("promotionItem.affiliateReferral.subTitle", { deposit: depositCondition }) }}
				</AText>
				<div class="values">
					<MPrizeFund v-if="coins" :size="coins" is-svg :iconSize="28" icon="coins">
						<AText :size="28" :modifiers="['bold']" class="text">{{ numberFormat(coins) }}</AText>
					</MPrizeFund>
					<MPrizeFund v-if="entries" variant="entries" is-svg :iconSize="28" icon="entries">
						<AText :size="28" :modifiers="['bold']" class="text"
							>{{ numberFormat(entries) }}
							<AText :modifiers="['semibold', 'uppercase', 'left']" class="for-free">
								{{ t("for free") }}
							</AText>
						</AText>
					</MPrizeFund>
				</div>
			</div>
		</template>
		<template #bottom>
			<AButton variant="primary" size="xl" class="foot">
				<AText :size="18" :modifiers="['medium']">
					{{ t("promotionItemsPreset.qualify") }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.affiliate {
	--a-card-img-width: 313px;
	--a-card-img-height: 386px;
}

.prizes {
	margin-top: 28px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.title {
		text-shadow: 0 4px 4px rgba(var(--calamba-rgb), 0.25);
	}

	.text {
		text-shadow:
			0 10px 15px rgba(var(--corlu-rgb), 0.1),
			0 4px 6px rgba(var(--corlu-rgb), 0.05);
	}
}

.values {
	display: flex;
	flex-direction: column;
	margin-top: 12px;
}

.image {
	width: 290px;
	height: 203px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.foot {
	width: 100%;
}

.for-free {
	display: inline-block;
	width: 36px;
}
</style>
